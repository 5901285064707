import {
  WebMercatorViewport,
} from '@math.gl/web-mercator'

/*
 * Mapbox operates at zoom levels [0, 20] but we really don't want to use that whole spectrum
 */
const MIN_ZOOM = 10
const MAX_ZOOM = 15
const DEGRESS_EARTH = 360

/*
 * Given an array of coordinates, pixel sizes of map, and a zoom level, return a mapbox
 * viewport which contains all of the coordinates.
 */
export default ({
  coordinates,
  mapHeightPx,
  mapWidthPx,
  zoom=MIN_ZOOM,
  paddingLeftPx=0,
  paddingBottomPx=0 }) => {
  if (process.browser && !Number.isFinite(mapHeightPx)) {
    mapHeightPx = document.documentElement.clientHeight
  }

  if (process.browser && !Number.isFinite(mapWidthPx)) {
    mapWidthPx = document.documentElement.clientWidth
  }

  const longitudes = coordinates.map(coordinate => coordinate.longitude).sort()
  const latitudes = coordinates.map(coordinate => coordinate.latitude).sort()

  let centerLongitude = (longitudes[0] + longitudes[longitudes.length - 1]) / 2
  let centerLatitude = (latitudes[0] + latitudes[latitudes.length - 1]) / 2

  zoom = Math.max(MIN_ZOOM, Math.max(zoom, MAX_ZOOM))

  const viewport = new WebMercatorViewport({
    longitude: centerLongitude,
    latitude: centerLatitude,
    width: mapWidthPx,
    height: mapHeightPx,
    zoom,
  })

  const center = viewport.getMapCenterByLngLatPosition({
    lngLat: [coordinates[0].longitude, coordinates[0].latitude],
    pos: [(mapWidthPx + paddingLeftPx) / 2, (mapHeightPx - paddingBottomPx) / 2],
  })

  return {
    zoom,
    longitude: center[0],
    latitude: center[1],
  }
}
